@import "./style-vars.scss";

body {
    color: rgb(112, 134, 158);
    font-family: 'Lato', sans-serif;
    font-size: 13px;
}

.page-header {
    border-bottom: solid 2px #eff3f5;
    margin-top: 0;
    padding: 40px $page-margin-size 30px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);

    h1 {
        margin-top: 0;
    }

    small {
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: $deemphasized-text-color;
        margin-top: 15px;

    }
}

h1 {
    color: rgb(76, 96, 114);
}

.btn {
    border-radius: 50px;
}

.table {
    thead {
        tr {
            th {
                text-transform: uppercase;
                color: $emphasized-text-color;
                border-bottom: 1px solid $border-color;
            }

            td {
                border-bottom: 1px solid $border-color;
            }
        }

    }

    tbody {
        tr {
            td {
                padding: 15px;
                border-bottom: 1px solid $border-color;
            }
        }
    }

}

#root {
    form {
        label {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.5px;
            color: $emphasized-text-color;
        }

        $input-underline-color: #594584;

        input {
            border: none;
            border-bottom: 1px solid $input-underline-color;
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            font-weight: 500;
            color: $deemphasized-text-color;
        }

        input:focus {
            border: none;
            border-bottom: 2px solid $input-underline-color;
            box-shadow: none;
        }

        ::placeholder {
            font-size: 13px;
            font-weight: 500;
            color: #d8e0e6;
        }

        span.input-group-addon {
            border: none;
            background: none;
            font-size: 13px;
            font-style: italic;
            color: $deemphasized-text-color;
        }

        button {
            border-radius: 100px;
            background-color: $highlight-color;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
            color: white;
            border: none;
        }

        button:hover {
            background-color: lighten($highlight-color, 10);
            color: white;
        }
    }
}

// React-spinner is 32x32 px. Contrary to what the docs suggest, you cant make this smaller. This causes problems when
// placing the spinner in a Bootstrap form element addon, since elements need to be ~16px to display nicely inside
// addons. This style override shrinks spinners within addons so they look nice.
.input-group-addon {
    .react-spinner {
        width: 16px;
        height: 16px;
        margin-top: 8px;
        margin-bottom: -8px;
    }
}
