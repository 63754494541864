.survey-status-5 {
  color: #2a9832;
}
.survey-status-4 {
  color: #3dd148;
}
.survey-status-3 {
  color: #999999;
}
.survey-status-2 {
  color: #e4567b;
}
.survey-status-1 {
  color: red;
}

