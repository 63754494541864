body {
  padding-bottom: 20px;
}
.container {
	padding-left: 0;
	width: 100% !important;
}
.navbar {
  margin-bottom: 0px;
  background-color: #e9ecef;
  background-size: cover;
  border-radius: 0 !important;
  min-height: 25px !important;

  a {
  	color: #337ab7;
  }

}

a {
	color: #0056b3;
}

h3.peerReportTitle{
	border-top: none;
    color: #666;
    padding-bottom: 10px;
    margin-top: 15px;
}

.clearBoth{
	clear: both;
}

table.reviewLocationList, div.replyWithStars{
  border-collapse: collapse;
  border-radius: 1em;
  	thead tr {
  			background: #374c89;
  			th { color: #fff !important; }
  	}
  	
	tr {
		border: 1px solid #999 !important;
		padding: 0;
		cursor: pointer;
	}

	td {
		border: 1px solid #999 !important;
		p:first-of-type{
			font-weight: bold;
			font-size: 15px;
		}
		p {
			font-size: 14px;
			padding: 0px;
			margin: 1px 10px;
		}
	}

	.rating {
	  unicode-bidi: bidi-override;
	  color: #c5c5c5;
	  font-size: 25px;
	  height: 25px;
	  width: 100px;
	  margin: 0 auto;
	  position: relative;
	  padding: 0;
	  text-shadow: 0px 1px 0 #a2a2a2;
	}

	.rating-upper {
	  color: #e7711b;
	  padding: 0;
	  position: absolute;
	  z-index: 1;
	  display: flex;
	  top: 0;
	  left: 0;
	  overflow: hidden;
	}

	.rating-lower {
	  padding: 0;
	  display: flex;
	  z-index: 0;
	}

	.averageRatingScore {
		padding-top: 10px;
		font-weight: normal !important;
		font-size: 12px !important;
	}
}
div.replyWithStars .rating{
	margin: 0;
	margin-left: 10px;
}
.reviewRefreshBtn {
	margin-left: 15px;
	font-size: 12px;
	line-height: normal;
	margin-bottom: 5px;
	padding: 5px 7px;
}

.searchEmployeeContainer{
	min-height: 80px;
	div.col-md-3{
		padding-right: 0;
	}
	.searchEmployeeBoxHolder {
			margin-top: 0;
	}

}

.writtenStaffReportContainer {
	label{
			font-size: 14px;
			margin-bottom: 0;
	}
	.customLocationRange{
			margin-top: 0;
	}
}
table.publicReviewTable{
	cursor: default;
	thead{
			position: sticky;
			top: 0;
	}
	th.table-blank{
			background-color: #fff;
	}
	td, th{
		text-align: center;
		vertical-align: middle;
	}
	td.first, th.first{
		text-align: left;
	}
	td {
			p{
				margin: 0;
			}
	}
	p.reviewReportPercent{
			font-size: 10px;
	}
	tr.reviewReportTotalLine{
			font-weight: bold;
			color: #343434;
	}
}
.reviewAccountContainer{
	h5{
		padding-bottom: 0;
	}
	h4{
		font-weight: bold;
	}
}

.dateInReply{
	display: block;
}
div.reviewDetailPage{
	background-color: #eee;
	max-width: 500px;
	padding: 15px;
	margin-bottom: 25px;
	p {
		margin: 0;
		padding: 0 15px;
	}
}
p.reviewReply{
	padding: 25px !important;
	background-color: #EEE;
	font-weight: normal !important;

	span.yourReplyTitle {
		font-weight: bold;
		display: block;
		margin: 0;
	}
}

a.peerReviewLink{
	font-size: 10px;
	text-align: center;
	display: block;
}

.leftPadding {
	padding-left: 25px;
}

.yesColumn{
	background-color: #a6dcad;
	color: #000;
}

.noColumn{
	background-color: #d4a4a7;
	color: #000;
}

h1 .submenuNav {
	background: none;
	box-shadow: none;
	line-height: 1rem;
	border: 0;

	button {
		background-color: #fff;
		color: #333;
	}
}

nav.submenuNav .navbar-nav a.employerTabs,
nav.submenuNav .navbar-nav a.employerTabs:hover, nav.submenuNav .navbar-nav a.employerTabs.active {
  background-color: #55679b;
  color: #ddd;
  margin-right: 1px;
}
nav.submenuNav .navbar-nav a.employerTabs.active {
	color: #fff;
}
.alignLeft15{
	left: 16px;
}
.adminBadgeView {
    padding-right: 25px;
}
.text-intake{ color: #00ff00 !important; }
.isBadge { display: inline-block; }
.intakeBadgeName { display: block !important; font-size: 10px !important; line-height: 25px !important;}
.container.inlineNextButton.intakeSubmit { height: 50px; padding-top: 10px;}
.specificScore { font-size: 14px; float: right; text-align: right;}
.filterRow{
	margin-top: -33px;
}
.btn-primary{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.filterRow .col-md-3{
	min-width: 225px;
	max-width: 225px;
	.form-control { border-radius: 0px;}
	label{
		font-size: 14px;
		color: #666;
	}

	.customLocationRange {
		margin-top: 0;
		.dropdown button {
			font-size: 14px;
		}
	}
}

		.dropdown button {
			background-color: #fff;
			color: #333;
			border-color: #333;
			margin-bottom: 10px;
		}

.filterRow .col-md-3.lastOne{
	padding-left: 0;
}

.searchContainerSub {
	max-width: 320px;
	margin-left: 20px;
}



.removeStaffFilter{
	display: inline-block;

		h3 {
				font-size: 12px;
				vertical-align: super;
				padding-left: 5px;
				margin: 0;
		}
}


.customDateRange {
	margin-top: 15px;
	background-color: #efefef;
	padding: 15px;
}

.text-underline{
	text-decoration: underline;
}
.customLocationRange {
	margin-top: 15px;
}

.extraDropDown{
	padding-bottom: 15px;
}
.customLocationRange .dropdown button {
	font-size: 12px;
}

.exportButton a, .exportButton a:visited, .exportButton a:hover, .exportButton a:active {
	color: #fff;
}

.externalSurveyLinks a {
	color: #333333 !important;
}

.beforeExternalLinks {
	font-weight: normal !important;
	margin: 25px !important;
	color: #333 !important;
}

.rbt-input-hint {
	display: none !important;
}

.smallSubHead{
	vertical-align: super;
	font-size: 12px;
	font-style: italic;
	font-weight: normal;
	float: right;
}

.customDateRange .DayPickerInput input {
	font-size: 16px;
}

.DayPicker{
	font-size: 1rem !important;
}

.updatePasswordBtn {
	display: block;
	clear: both;
	margin-top: 20px;

}

.userActionList td a {
	display: block;
	min-width: 105px;
}

.glyphicon-cog {
	color: #374c89;
	font-size: 30px;
}

.stand-out-line{
	color: #000;
	font-weight: bold;
}

.navbar span {
	font-size: 16px;
	color: #333;
	padding: 5px 0 15px 0;
}

nav.navbar {
	padding: 0 108px 0px;
}

h1 nav .navbar-nav a{
	font-size: 13px;
}

.actualSurvey .navbar {
  background-color: #e9ecef;
  background-size: cover;
  border-radius: 0 !important;
  min-height: 35px !important;
  z-index: 10000;
  position: fixed !important;
  width: 100%;
  margin: 0 !important;
  margin-bottom: 10px;
}

.actualSurvey .navbar.fixed-bottom {
	min-height: 1px !important;
	background: #fff !important;
	padding-top: 1px !important;
}

.row.cardholder{
	padding-left: 3px;
}
.surveyPrizeWinnings{
	font-size: 16px;
	text-align: center;
	color: #333;
	width: 100%;
}
.cardholder .card {
	width: 3rem;
}

.visitCalContainer,
.aboutYouContainer {
	padding-top: 20px;
}

.card {
	border: 0;
	width: 100%;
	margin: 3px;
}

.cardInstructions {
	text-align: center;
	width: 100%;
	color: #666;
	font-size: 10px;
	text-transform: capitalize;
}

.cardInstructions p {
	margin: 0 auto;
	padding-left: 15px;
}

.card a{
	background-repeat: no-repeat;background-size: contain;
	width: 100%;
	height: 60px;
}

.backLink{
	color: #337ab7 !important;

	div {
		display: inline-block;
	}
}

.formEntry {
	margin: 0 auto;
}

.modal-dialog{
	margin-top: 55px !important;

	.modal-body{
		font-size: 14px;
	}

	button {
		background: #145a8c;
		color: #fff;
	}
}
.modal-footer .btn + .btn {
	margin-left: 15px !important;
}

h5 { padding-bottom: 15px; }

.nopad {
	padding-left: 8px;
	padding-right: 8px;
	margin: 0 auto;
}

.adminFooter{
	padding-top: 50px;
	font-size: 10px;
	color: #3a8390 !important;
	text-align: center;
	a {
		color: #3a8390 !important;
		text-decoration: none;
	}
	display: block;
	clear: both;
}

.googleExplainText{
	width: 50%;
	min-width: 400px;

	span {
		font-weight: bold;
		text-decoration: underline;
	}
}

.maxWidthStaffGoogle {
	max-width: 60%;
	img{
		max-width: 100%;
	}
}
.centerLocationText{
	text-align: center;
	margin-left: 0;
}
.reviewPageAlignCenter{
	margin: 0 auto;
}

img.googleSignin{
	cursor: pointer;
}

.actualSurvey .nopad {
	padding-left: 15px;
	padding-right: 15px;
	max-width: 111px;
	min-width: 105px;
	vertical-align: top;
	margin: 0;
}

.staffDetails{
	display: inline-block;
	width: 56%;
	padding: 9px 9px 4px 9px;
}

.actionableRecord {
	height: 40px;
	cursor: pointer;
}

.staffDetails p {
	font-size: 14px;
	color: #333;
	line-height: 18px;
}

textarea { border:0; width: 95%; margin: 10px;}

.menu { background: #e9ecef; width: 120%; padding:5px; }
.menu a { font-size: 14px; color: #333; margin: 10px; font-weight: bold;}

.staffDetails p.name, .staffDetails p.badgeTitle, p.deptName { font-weight: bold;margin-bottom: 0; }
p.deptName { padding: 15px 0; }

.staffImage img {  vertical-align: top; }
.deptImage img {width: 40%; vertical-align: top; padding: 15px;}

.alternateTextLink {
	font-style: italic;
}

button.padLeft{
	margin-left: 15px;
}

.badgeListContainer{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  min-height: 100px;
  max-width: 650px;
  span {
  	display: inline-block;
  	padding-right: 15px;
  	text-align: center;
  	position: relative;
	p {
		font-size: 11px !important;
	  	display: block !important;
	  	text-align: center !important;
	}
	img{
	  	margin-right: 0 !important;
	}
  }

}

p.badgeCountArea {
	position: absolute;
    background-color: #374c89;
    padding: 3px;
    border: 1px solid #fff;
    right: 5px;
    border-radius: 50%;
    width: 24px;
    font-weight: bold !important;
    color: #fff;
}

.staffList img {
	width: 90px;
	border-radius: 50%;
	border: 1px solid #ccc;
	margin: 0 auto;
	max-height: 90px;
}

p.department { margin-bottom: 4px; }

.backButton {
	font-size: 14px;
	padding: 0 0 0 10px;
	margin-left: 10px;
	position: fixed;
	left: 10px;
	line-height: 35px;
}

.simulateAnchor{
	background: none !important;
    color: #0056b3 !important;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    padding-left: 0 !important;
}
.simulateAnchor:hover, .simulateAnchor:focus, .simulateAnchor:active{
	text-decoration: underline;
}

.actualSurvey .simulateAnchor{
	padding-right: 0 !important;
}

.floatingNext, .floatingNextStaff, .floatingDeptNext {
	float: right;
}
.extraRightMargin{
	margin-right: 15px;
}

.extraBottomMargin{
	margin-bottom: 15px;
	min-height: 20px;
}

.DayPickerInput {
	margin-right: 25px;	
}
.reviewSite {
	img {
		max-width: 100%;
	}

	width: 100%;
	text-align: center;
	cursor: pointer;
}

td.deptImageAdmin img{
	max-width: 92px;
}

.prizeSpinContainer {
	position: relative;
	max-width: 100%;
	padding-top: 35px;
	margin: 0 auto;
	div {
		max-width: 100%;
	}
	div.prizeTicker{
	    width: 15px;
	    height: 30px;
	    line-height: 30px;
	    background: transparent;
	    position: absolute;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    content: "";
	    position: absolute;
	    border: 15px solid transparent;
	    border-top-color: #000;
	    left: 50%;
	    margin-left: -15px;
	    top: -30px;
	    z-index: 1;
	    top: 43px;
	}
	div.react-turntable-section-btn {
		bottom: auto;
		width: 55px;
		height: 55px;
		line-height: 55px;
		top: -40px;
		font-size: 15px;
		background: #145a8c;
	}
	div.react-turntable-section-btn::before {
		display: none;
	}

	.removeItem {
		display: none;
	}
}

	a.ruleLink{
		position: absolute;
		top: 475px;
	}

canvas{
	max-width: 100%;
}

.DayPickerInput input{
	padding: 5px;
}

.innerTitleWinners{
	margin: 50px 0 15px 0;
	color: #333;
}

.case-list-container.potentialWinner {
	padding-left: 0;
	margin-top: 25px;
	background-color: #eee;
	color: #000;

	p, a {
		display: inline-block;
		font-size: 15px;
		padding: 10px;
		margin: 0;
	}
}

.dayPickerLabel {
	font-size: 14px;
	color: #333;
	font-weight: normal;
	margin-right: 10px;
}

.prizeDrawTitle{
	font-size: 16px;
	color: #333;
}

img.editStaffImgThumb {
	max-width: 180px;
}

.cycleStaffButton {
    font-size: 12px;
    padding: 5px 15px;
}

.badgeSelector { padding-top:15px; padding-left: 10px;}
.badgeSelector img {
	width: 75%;
}

.badgeSelector p { font-size: 12px; margin-bottom: 0px; }

.badgeSelector.intakeForm{
	min-height: 500px;

	p{
		margin-bottom: 10px;
		line-height: 20px;
		font-weight: normal;
	}

}

.image-checkbox, .staff-checkbox, .badge-checkbox {
	cursor: pointer;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: 2px solid transparent;
	margin-bottom: 0;
	outline: 0;
}
.badge-checkbox input[type="checkbox"],
.staff-checkbox input[type="checkbox"],
.image-checkbox input[type="checkbox"] {
	display: none;
}

.hidden{ display: none; }

.badge-checkbox-checked { opacity: 0.4; }
.staff-checkbox-checked,
.image-checkbox-checked {
	opacity: 0.5;
}
.badge-checkbox .fa,
.staff-checkbox .fa,
.image-checkbox .fa {
  color: #4A79A3;
  background-color: #fff;
  padding: 10px;
  top: 0;
  right: 0;
}
.badge-checkbox-checked .fa,
.staff-checkbox-checked .fa,
.image-checkbox-checked .fa {
  display: block !important;
}

h2 .badgeOnReport {
	max-width: 50px;
	display: inline-block;
	margin-right: 10px;
}

.card-img-starrating{
	background: url("/survey_assets/starrating.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}

.card-img-sad{
	background: url("/survey_assets/sadface2.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}
.card-img-meh{
	background: url("/survey_assets/mehface2.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}
.card-img-okay{
	background: url("/survey_assets/okayface2.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}
.card-img-good{
	background: url("/survey_assets/goodface2.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}
.card-img-great{
	background: url("/survey_assets/greatface2.jpg") 0 0;
	font-size: 0;
	color: transparent !important;
}

.mr-auto, .mx-auto { margin-right: auto!important; }
.ml-auto, .mx-auto { margin-left: auto!important; }

.row {
	  display: flex;
  flex-wrap: wrap;	
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: none;
}

.pagination{
	align-items: center;
	justify-content: center;
	padding-top: 15px;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #337ab7;
}

.page-link:focus {
	box-shadow: none;
}
.page-item.active .page-link {
	z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}

.surveyPageTitle {
	width: 100%; text-align: center;
	font-weight: bold; color: #333; font-size: 18px;
	margin-top: 53px;
	display: inline-block;
	font-family: SANS-SERIF;
	margin-bottom: 0;
}

.dashboardImgFix{
	margin-left: 50px;
	width: 96%; 
	max-width: 1450px;
}

.locationSpecificReportFilter {
	padding-right: 15px;
}
.locationSpecificReportPieSpace {
	margin-left: 10px;
	display: inline-block;
}
.locationSpecificReportMiddle{
	margin-left: 25px;
}

.senderStat {
	position: absolute;
	right: 10px;
}

.case-list-container table.case-list.employerTabler tbody tr{
	height: 50px;
}

.case-list-container{
	padding-right: 121px;
	padding-left: 121px;
	h4.reportHeader, h5.reportHeader { color: #374c89; text-decoration: underline; margin-bottom: 0; padding-bottom: 10px; }
	.row { border-bottom: 1px solid #eee; padding-top: 10px; padding-bottom: 10px;}
	div { cursor: pointer; }

	.adminBadgeText { padding-top: 8px; }

 	.filterByDepartmentGroup {
 		width: 45%;
 		display: inline-block;
 		label {
 			padding-top: 5px;
 			font-weight: bold;
 			display: block;
 		}
 	}

}
.headerSpacer {
	display: inline-block;
	padding-top: 25px;
	margin-left: 15px;
	color: #374c89;
	padding-bottom: 15px;	
}

.reportBlockspacer{
	margin-bottom: 15px;
}

.npsSurveyTable {
	width: 100%;
	text-align: center;
	font-size: 14px;

	th{
		text-align: center;
		border-bottom: 1px solid #374c89;
		padding: 10px;
	}
}

.total-survey-chart-container {
	min-height: 300px;
	max-width: 100%;
	h5.dashboardCmpTitle{
		color: #4c6072;
		font-weight: bold;
		padding-top: 15px;
	}

	div.recharts-responsive-container {
		margin: 0 auto;
		background-color: #fff;	
	}

}

div.staffReportDetailContent{
	background: #fff;

	.extraBottomMargin {
		padding-right: 0;
	}

	tr.reportBadgeContainer td {
		vertical-align: inherit;
		min-width: 200px;
	}

}
td.totalBox{
	font-size: 16px;
	border-bottom: 2px solid #333;
}

p.hoverBadgeType{
    position: relative;
    /* float: right; */
    margin: 0px 0 -20px 0px;
    width: 40%;
    padding: 5px;
    vertical-align: top;
    background-color: #2e6da4;
    color: #fff;
}

div.componentSpacer {
	margin-top: 25px;
}
div.staffReportDetails{
	padding-top: 20px;
	font-size: 16px;
	table{
		width: 100%;
	}
	td {
		padding: 5px 15px 5px 15px;
		border: 1px solid #eee;
	}
	td.staffHeaderCell{
		color: #fff;
		background-color: #374c89;
		width: 135px;
	}
	p {
		margin-bottom: 4px;
	}
	p.wellIQRank{
		margin: 15px 0 0 0;
		padding-left: 160px;
		font-size: 18px;
		background: url("/newlogo.png") 0 0;
		background-repeat: no-repeat;
		min-height: 30px;
		line-height: 30px;
		background-size: contain;
	}
	p.rankVariables{
		font-size: 12px;
	}

	.nps-survey-container text {
		font-size: 24px;
	}
	.nps-survey-container h5 {
		font-size: 18px;
	}
	.nps-survey-container span {
		font-size: 14px;
		padding-left: 10px;
		padding-top: 3px;
	}

	.orgStatDetail {
		font-size: 11px;
		font-style: italic;
		text-align: center;
	}

}

	p.noDataLine {
		padding: 15px 0 0 15px;
		font-size: 14px;
	}

.case-list-container.piechart{
	margin-left: 15px;
}

.reportingPages .case-list-container.piechart{
	display: inline-block;
	margin-left: 0;
}

.reportingPages h2 {
	margin-left: 0;
}

.reportingPages .middleCptContainer{
	display: inline-block;
}

.nps-survey-container{
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
	padding: 0 !important;
	div {
		margin-left: 0;
	}
	h5 {
		color: #FFF;
		background: #374c89;
		padding: 10px;
		cursor: default;
		margin-top: 0;
		margin-bottom: 0;

		a{
			color: #fff;
		}

		a:hover {
			text-decoration: none;
			color: #fff;
		}
	}
	text{
		font-size: 40px;
	}
}

.middleCptContainer{
	
	.totalSurveys {
		cursor: default;	
		background: #bceaec;
		min-height: 100px;
		margin-bottom: 5px;
		display: flex;
		img{
			margin: auto;
		}
		.detailedInfo{
			display: block;
			margin: auto;
			min-width: 179px;
			padding-right: 50px;
			h4{
				font-weight: bold;
			}
			h4,h5{
				margin: auto;
				padding-bottom: 0;
			}
		}
	}
	.totalCompleted {
		cursor: default;	
		background: #cbecbc;
		margin-top: 5px;
		min-height: 100px;
		display: flex;
		margin-bottom: 5px;
		img{
			margin: auto;
		}
		.detailedInfo{
			display: block;
			margin: auto;
			min-width: 179px;
			padding-right: 50px;
			h4{
				font-weight: bold;
			}
			h4,h5{
				margin: auto;
				padding-bottom: 0;
			}
		}
	}	
	.totalOpened {
		cursor: default;	
		background: #DDD6EA;
		margin-top: 5px;
		min-height: 100px;
		display: flex;

		img{
			margin: auto;
		}
		.detailedInfo{
			min-width: 179px;
			display: block;
			margin: auto;
			padding-right: 50px;
			h4{
				font-weight: bold;
			}
			h4,h5{
				margin: auto;
				padding-bottom: 0;
			}
		}
	}
}


.statBadgeContainer, .statDeptContainer {
	margin-left: 25px !important; 
	padding-top: 5px;
	label{
		margin-bottom: 0;
		img{
			max-width: 44px;
			padding: 0;	
			display: inline-block;
			margin-right: 20px;
		}
		p{
			display: inline-block;
			font-size: 20px;
			font-weight: normal;
			margin-bottom: 10px;
		}
		p.engagementCount{
		    display: inline-block;
		    position: absolute;
		    right: 0;
		    background: #eee;
		    width: 100px;
		    padding: 0;
		    line-height: 60px;
		    text-align: center;
		    margin: 0;
		}
	}
}

.adminDashboardBadges .statBadgeContainer label p {
	overflow: hidden;
	max-width: 310px;
	height: 40px;
	line-height: 40px;
	vertical-align: middle;
}

.maxWidthSelectContainer{
	max-width: 280px;
}

.text-orange {
	color: darkorange !important;
}

.reportBadgeContainer {
	margin-left: 25px !important; 
	padding-top: 5px;
	border: 1px inset #d4dcf3;
	td, th {
		padding: 15px;
		margin-bottom: 0;
		font-size: 16px;
		border-bottom: 1px solid #eee;
		img{
			max-width: 70px;
			padding: 0;	
			display: inline-block;
			margin-right: 20px;
		}
		p{
			display: inline-block;
			font-size: 20px;
			font-weight: normal;
		}
		p.engagementCount{
		    display: inline-block;
		    position: absolute;
		    right: 0;
		    background: #eee;
		    width: 100px;
		    padding: 0;
		    line-height: 60px;
		    text-align: center;
		    margin: 0;
		}
	}
	td.totalTdValue {
		background-color: #d4dcf3;
		color: #333;
		font-weight: bold;
	}
}

table.case-list {
	thead tr th {
		border-top: 0;
	}
}

.reportStaffNameContainer {
	min-width: 280px !important;
}

tr.reportBadgeContainer.table-headings th {
	color: #fff;
	min-width: 160px;
}

.reviewAccountContainer{
	h5{
		padding-bottom: 0;
	}
	h4{
		font-weight: bold;
	}

	tr.reportBadgeContainer th{
		min-width: inherit;
		padding: 10px;
	}
	sup {
		margin-left: 4px;
	}
	tr.actionableRecord {
		cursor: auto;
	}
}

.locationAccountContainer{
	margin-left: 15px;
}


a:not([href]) {
	cursor: pointer;
}

.popoverButton{
	max-height: 38px;
}

.input-group-prepend p {
	padding: 0 5px;	
	margin-bottom: 0;
}

.adminDepartmentSelection select, .adminLocationSelection select{
	min-height: 175px;
}

.popover-body .form-group {
	margin-bottom: 0;
}

.addBottomMargin {
	margin-bottom: 50px;
}

.reportBadgeContainer.table-headings{
	background-color: #374c89;
	color: #fff;
	font-size: 16px;
}


.statDeptContainer{
	border-bottom: 1px solid #efefef;
	padding-top: 0;
	min-height: 50px;

	label{
		line-height: 50px;
	}

	td {
		img {
			max-width: 70px;
			padding: 0;	
			display: inline-block;
			margin-right: 20px;		
		}
	}
}

.first-survey-container{
	border-right: 1px solid #CCC;
}

legend.uploadPhotoHeader{
	margin: 5px 0px 0px 0px !important;
}

.addressable-events {
	width: 100%;
	h5{
		background: #b1475f;
	}	

	table {
		width: 100%;
	}

	table th{
		background: #f7ecef;
	}
}

.locationContainer{
	background: #f7f7f7;
	width: 200px;
	border: 1px solid #ccc;
	padding: 9px 15px 9px 15px;
	cursor: default !important;
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
	min-height: 180px;
	position: relative;

	h5 {
		font-weight: bold;
		margin-bottom: 0;
		padding-bottom: 5px;
		max-height: 50px;
		overflow: hidden;
	}

	p {
		padding: 0;
		margin: 0;
		max-height: 20px;
		overflow: hidden;
	}

	a {
		bottom: 15px;
		right: 15px;
		position: absolute;
		background: #f7f7f7;
	}
	a.addPeople {
		left: 15px;
		right: inherit;
	}

}


.patientSearchBox{
	float: right;
	min-width: 310px;

	input {
		width: 70%;
		float: left;
		margin-right: 10px;
	}

}

.journeyContainer {
	min-height: 100px;
	text-align: center;
}


.addNewLocationBtn{
	background: #21409a;
	padding: 10px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	float: left;
	text-decoration: none;
}

.newUserButton {
	float: none;
	display: inline-block;
	margin-bottom: 25px;
}

.addNewLocationBtn:hover, .addNewLocationBtn:visited, .addNewLocationBtn:active,.addNewLocationBtn:focus {
	color: #fff;
	background: #2749ad;
	text-decoration: none;
}

	form.new-case-form legend {
		margin-top: 40px;
	}

	form.new-case-form {
	    width: 600px;
	    margin-left: auto;
	    margin-right: auto;
	}

.messagePatientForm {
	.form-group{
		label {
			display: block;
			margin-bottom: 0;
		}

		.radio {
			margin-top: 0;
			display: inline-block;
			margin-right: 15px;
			
			label{
				line-height: 19px;
				font-weight: normal !important;
			}
		}

	}

}

.radio {
			input{
				vertical-align: text-bottom;
				margin-right: 5px;
			}
}

.sequenceDepartments{
	ul {
		border: 1px solid #ccc;
		list-style: none;
		padding-left: 0;
		background-color: #eee;
		cursor: pointer;

		li{
			background-color: #eee;
			color: #000;
			height: 50px;
			line-height: 50px;
			font-size: 16px;
			min-width: 200px;
			padding-left: 20px;
			border-bottom: 1px solid #ccc;
			cursor: pointer;
		}
		li:last{
			border-bottom: none;
		}
	}
}

@media only screen and (max-width: 420px) {
	.auth-form-container form {
		margin-top: 25px;
	}
	.page-header {
		padding: 25px !important;
	}
	.case-list-container.piechart{
		padding-left: 25px;
		padding-right: 37px;
	}
	.nps-survey-container {
		min-width: 340px;
	}
	.middleCptContainer {
		padding-left: 0;
		min-width: 355px;
	}
	.case-list-container {
		padding-left: 25px !important;
		padding-right: 25px;
	}
	.case-list-container .filterByDepartmentGroup button {
		width: 60%;
	}
}


@media print {

	@page  
	{ 
	    size: auto;   /* auto is the initial value */ 

	    /* this affects the margin in the printer settings */ 
	    margin: 0 0 0 0;  
	} 
	.page-header, .case-list-container{
		padding: 0;
		margin: 0;
	}
	.nps-survey-container {
		float: left !important;
		display: block;
	}

	.locationSpecificReportFilter, .filterDropDown, .btn-group,.case-list-container.stand-out-line {
		display: none !important;
	}

.nps-survey-container h5 {
    color: #000 !important;
    text-decoration: underline;
    padding: 10px;
    cursor: default;
    margin-top: 0;
    margin-bottom: 0;
}
p.badgeCountArea {
	color: #fff !important;
	background-color: #374c89 !important;
}


.locationSpecificReportMiddle {
	display: block !important;
	padding-top: 30px !important;

	.middleCptContainer{
		float: left !important;
	}
	
}

.middleCptContainer .totalSurveys {
	background-color: #bceaec !important;
}
.middleCptContainer .totalOpened{
	background-color: #DDD6EA !important;
}
.middleCptContainer .totalCompleted {
	background-color: #cbecbc !important;
}

	.statBadgeContainer, .statDeptContainer {
	 	margin-left: 25px !important; 
		padding-top: 5px;
		label{
			margin-bottom: 0;
			img{
				max-width: 44px !important;
				padding: 0;	
				display: inline-block;
				margin-right: 20px;
			}
			p{
				display: inline-block;
				font-size: 20px;
				font-weight: normal;
			}
			p.engagementCount{
			    display: inline-block;
			    position: absolute;
			    right: 0;
			    background: #eee;
			    width: 100px;
			    padding: 0;
			    line-height: 60px;
			    text-align: center;
			    margin: 0;
			}
		}
	}

	.maxWidthSelectContainer{
		max-width: 280px;
	}

	.reportBadgeContainer {
		margin-left: 25px !important; 
		padding-top: 5px;
		border: 1px inset #d4dcf3;
		td, th {
			padding: 15px;
			margin-bottom: 0;
			font-size: 16px;
			border-bottom: 1px solid #eee;
			img{
				max-width: 70px !important;
				padding: 0;	
				display: inline-block;
				margin-right: 20px;
			}
			p{
				display: inline-block;
				font-size: 20px;
				font-weight: normal;
			}
			p.engagementCount{
			    display: inline-block;
			    position: absolute;
			    right: 0;
			    background: #eee;
			    width: 100px;
			    padding: 0;
			    line-height: 60px;
			    text-align: center;
			    margin: 0;
			}
		}
		td.totalTdValue {
			background-color: #d4dcf3;
			color: #333;
			font-weight: bold;
		}
	}

	tr.reportBadgeContainer.table-headings th {
		color: #fff;
	}

	.addBottomMargin {
		margin-bottom: 50px;
	}

	.locationSpecificReportPieSpace {

		.col-md-7{
			min-width: 500px;
			float: left !important;	
		}
	}

	.case-list-container {
		padding-left: 60px !important;
	}
}

.requestPhotoForm {
	margin-left: 121px !important;
}

@media only screen and (max-width: 420px) {
	.requestPhotoForm {
		margin-left: auto !important;
		img {
			margin-left: 25px;
		}
	}
	form.new-case-form{
		max-width: 100%;
	}
}
