@import "../../style-vars.scss";

.surveyReportView {
  max-width: 1200px;
  outline: 1px solid #ccc;
}

.reportCell{
  background-color: #efefef;
    font-size: 18px;
    width: 25%;
    text-align: right;
    padding-right: 25px !important;
    border-right: 1px solid #ccc;
    border-color: #ccc !important;
    border-top: 0 !important;
}

.staffImageCell{
  background-color: #fff;
  text-align: center;

  img {
    height: 145px;
  }
}

.reportValue {
  font-size: 17px;
  width: 75%;
  padding-left: 25px !important;
  border-color: #eeeeee !important;
}

.reportStaffTitle{
  font-size: 14px;
  font-style: italic;
}

.rowEmphasis{
  border-top: 2px solid #ccc;
}

.spanningReportCell {
  background-color: #fefefe;
  height: 25px !important;
  font-size: 16px;

  label p {
    font-size: 14px;
    padding-top: 15px;
  }
}

.badge-card__icon-overlay.average,
.badge-card__icon-overlay-reporting.average,
.badge-card__icon-overlay-dashboard.average {
    background-image: url('/icons/endorsement-average.png');
}

.badge-card__icon-overlay.bad,
.badge-card__icon-overlay-reporting.bad,
.badge-card__icon-overlay-dashboard.bad {
    background-image: url('/icons/endorsement-bad.png');
}

.badge-card__icon-overlay.good,
.badge-card__icon-overlay-reporting.good,
.badge-card__icon-overlay-dashboard.good  {
    background-image: url('/icons/endorsement-good.png');
}



.badge-card__icon-overlay {
    position: absolute; /* Position relative to the container */
    top: 15px !important;
    right: 0px !important;
    left: auto !important;
    width: 36px;
    height: 180px;
    background-size: contain; /* Adjust as needed */
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; /* Prevent overlay from intercepting clicks */
}

.badgeListContainer .badge-card__icon-overlay{
  right: 15px !important;
  left: auto;
  top: 20px !important;
  height: 70px;
  width: 36px;
}

.badge-card__icon-overlay-reporting{
  position: absolute; /* Position relative to the container */
  top: 15px !important;
  right: 43px !important;
  left: auto !important;
  width: 36px;
  height: 80px;
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center;
}

.badge-card__icon-overlay-dashboard{
  position: absolute; /* Position relative to the container */
  top: 0;
  right: 0;
  left: 32px;
  width: 20px;
  height: 50px;
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center;
}

.badge-card__icon {
  width: 120px;
  height: 120px;
}

.badge-card__icon-wrapper{
 position: relative;
 max-width: 130px;
 margin: 0 auto;
}

.badge-card__icon-wrapper-dashboard{
  position: relative;
  max-width: 130px;
  margin: 0 auto;
  display: inline;
}

.badgeListContainer span img.badge-card__icon,
.reportBadgeContainer td img.badge-card__icon{
  height: 70px;
}

.badgeListContainer span p.badgeCountArea {
  right: -8px;
}



.surveyReportView.eventData{
  outline: 1px solid #fb4040;
  tr { height: 50px !important; }
  .reportCell{
    background-color: #fb4040;
    color: #ffffff;
  }
  .reportValue{
    color: #000 !important;
  }
  .reportValue.buttonAction{
    text-align: center;
  }
  .reportValue.textEntryArea{
    padding: 0 !important;
    margin: 0 !important;
    height: 75px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 15px 0 0 25px;
      margin: 0;
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}