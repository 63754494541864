@import "../../../style-vars";

.auth-form-container {
  .auth-form-background {
    background-image: linear-gradient(137deg, #145a8c, #145a8c 44%, #3a8390);
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  form {
    margin-top: 122px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    min-height: 510px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 50px;

    .company-logo, .company-logo-top {
      width: 100%;
      border-style: none;
      line-height:76px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: 25px;
    }

    .company-logo {
      margin-top: 40px;
    }

    .company-logo-bottom{
      width: 75%;
      margin-top: 25px;
      margin: 0 auto;
      padding-top: 20px;
      display: block;
      text-align: center;
    }

    .company-name {
      text-align: center;
      color: #145a8c;
      margin-bottom: 46px;
      font-size: 26px;
      font-weight: bold;
    }

    .bizLearnMore{
      font-size: 12px;
      text-align: center;
    }

    button {
      border-radius: 5px !important;
      width: 100%;
      margin-top: 15px;
      background-color: #145a8c !important;
    }

  }
}

