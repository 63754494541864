@import "../../style-vars.scss";

.case-list-container {

    padding-left: $page-margin-size;

    table.case-list {

        tbody {
            tr {
                height: 65px;
                td {
                    vertical-align: middle;
                    padding: 15px 8px 15px 8px;
                }
            }
        }

        th.sortable:hover {
            cursor: pointer;
        }
    }

    .case-list-summary {
        font-size: 13px;
        font-weight: 500;
        color: $deemphasized-text-color;
        padding: 0 0 8px 8px;
    }
}

#root {
    form.case-list-filters {
        label.control-label {
            text-transform: none;
            font-size: 15px;
            font-weight: normal;
            color: $deemphasized-text-color;
        }

        input {
            background-color: #f9fafc;
            border-radius: 100px;
            border: none;
            padding-left: 15px;
        }

        input:focus {
            border: none;
        }

        input:active {
            border: none;
        }
    }
}

.case-list-filters input {
    margin-left: 10px;
}

.surveyRow {
    cursor: pointer;
}

.altRowColor {
    background-color: #fafafa;
}

button.filterDropDown {
    border-radius: 0;
    margin-bottom: 12px;
}

button.dropdown-toggle{
    border-radius: 0;
}
.intakeBadgeC img{
  margin-right: 0 !important;   
}

tr.intakeListRow{
    border: none !important;
}