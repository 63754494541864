.visitCalContainer {
	margin: 0 auto; 
	padding-top: 0;
}
.DayPicker {
	font-size: 1.9rem;
	color: #333;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: 	#337ab7;
}

.languageSection a {
	color: #3e8191;
}

a:not([href]){
	cursor: pointer;
	color: #0056b3;
}
a:not([href]):hover{
	text-decoration: underline;
	color: #0056b3;
}


.DayPicker-Day--today { color: #333; text-decoration: none;}
.nextDirection { font-weight: bold; padding-top: 20px; font-size: 15px !important; }
p.selectedDay { margin: 0 auto; text-align: center; color: #333;}
.drinkTicketImage { margin: 25px; }
.nextBtn,.nextBtn:focus, .nextBtn:active, .nextBtn:visited, .nextBtn:hover { border-radius: 0; position: fixed; right: 0; background: #145a8c !important; }
.nextBtn.genentech { background: #850d70; border-color: #fff;}
.sDeptName { color: #333; padding-top: 10px;}

.submitInfoBtn{
	border-radius: 0;  background: #145a8c !important;
}

#myPersonalDetailsContainer{
	margin: 0 auto;
	padding: 10px 0px 25px 0px;
}

#myPersonalDetails{
	h4{
		margin-bottom: 10px;
		font-size: 18px;
	}

	div {
		display: block;
	}

	label {
		text-align: left;
		min-width: 75px;
		display: inline-block;
	}

	input{
		display: inline-block;
		font-size: 14px;
		min-width: 180px;
	}

	button{
		float: right;
		margin-top: 10px;
	}

}

img.activeDept {
	opacity: 0.25;
}

p.employerReviewPrompt{
	padding: 20px 0 15px 0;
	font-weight: bold;
	text-align: center;
}
.employerReviewSection{
	float: left;
	width: 230px !important;
	margin-bottom: 16px !important;
	p {
		padding-bottom: 2px;
		margin-bottom: 0;
	}
	.reviewSite img {
		max-width: 125px;
	}
	border-bottom: 1px solid #000;
}


label.badge-checkbox.addCheckMark::after,
label.image-checkbox.addCheckMark::after{
	content: "\2713 ";
	color: green;
	position: absolute;
	top: 0;
	right: 0;
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 15px;
}

.overallCard.notSelected{
	opacity: 0.2;
}

.npsSelected {
	display: none;
}
.npsSelected.yes {
	display: block;
}

.selectedCompanyInfo img, .selectedCompanyInfo p, .selectedCompanyInfo div { opacity: 0.25}

.moreInfoText { color: #333; margin-bottom: 10px;}
.navbar.fixed-bottom{
	min-height: 50px !important;
	background: transparent !important;
	width: 200px;

	button {
		border-radius: 15px 0 0 15px;
		font-size: 16px;
		font-weight: bold;
		padding: 12px 18px;
		text-transform: uppercase;
		bottom: 8px;
	}
}

.hrTop {
	background-color: #0073cb !important;

	a.backButton, .simulateAnchor { color: #FFF !important; }
	a.backButton { margin-left: 0 !important; }
}

p.addMoreFeedback {
	text-align: center;	
}

.listOfDepartments {
	cursor: pointer;
}

.centerButton{
	margin: 0 auto;
}

.noPad label img { width: 100%; }


.addNewButton { color: #333; margin-top: 25px; padding: 5px; margin-left: 25px;}

.staffDetails{
	display: inline-block;
	width: 56%;
	padding: 9px;
}

.staffDetails p {
	font-size: 14px;
	color: #333;
	line-height: 18px;
}

textarea { border:0; width: 95%; margin: 10px;}

.menu { background: #e9ecef; width: 100% !important; padding:5px; }
.menu p { font-size: 14px; color: #333; margin: 9px; font-weight: bold;}

.staffDetails p.name, .staffDetails p.badgeTitle, p.deptName { font-weight: bold;margin-bottom: 0; }
p.deptName { padding: 15px 0; }
.image-checkbox p.sDeptName { font-weight: normal; word-break: break-word;}

.actualSurvey .staffImage img { width: 40%; vertical-align: top; display: inline-block; max-width: 300px;}
.actualSurvey .navbar p { margin: 0 auto; text-align: center; }
.deptImage img {width: 40%; vertical-align: top; padding: 15px;}
.container{ padding-left: 0; padding-right: 0; 	margin: 0; max-width: 100% !important;}
.container.employerReviewSection{ width: 230px !important;}
.row.cardholder { padding-left: 20px !important; padding-right:20px !important; margin: 0;}
.row.cardholder.badges { padding-left: 10px; padding-right: 10px; }
.row.cardholder p, .row.cardholder label { color: #333; font-size: 14px;}
.row.cardholder p { width: 100%; font-size: 16px; }
.row.cardholder.serveSurvey { 
	padding-top: 25px; 
	.surveyPageTitle {
		margin-top: 25px;
	}
	
	max-width: 780px;
	margin: 0 auto;
	
}

.sponsorContainer {
	padding-top: 15px !important;
}

.smallerText { font-size: 12px !important; text-transform: none;}
.hupcText {font-size: 15px !important; color: #d71818 !important; }
.heartcityText {font-size: 15px !important; color: #333333 !important; }
.striveText { font-size: 15px !important; }
.row.moreInfoHolder{ margin: 0; padding-left: 10px; padding-right: 10px;}
.moreInfoImage img { max-width: 250px;}

.separator { border-bottom: 1px solid #ccc;}
.isBadge { display: inline-block; }
.badge-checkbox, .image-checkbox { border: none; overflow: hidden; position: relative }
.badge-checkbox { font-weight: 700;}
.badgeSelector { max-height: 315px; overflow: auto;}
.badgeSelector img { width: 100% !important;}
.badgeSelector p { padding-top: 5px;  height: 38px;}
span.extraInfo {font-size: 12px;}

.deptImageFix { padding: 15px; }
.actualSurvey .form-group { width: 80%; max-width: 400px; clear: both;}
.actualSurvey .nopad { padding-right: 5px !important; padding-left: 4px !important;}

.productIntakeForm .nopad {
	max-width: 145px !important;
}

.productIntakeForm.forWhom, .productIntakeForm.fromWhere{
	min-height: 250px;

	.nopad{
		max-width: 111px !important;
	}
} 



.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
  font-size: 12px;
}
.actualSurvey .selfIdentifyForm .form-group {
	width: 100%;
}

.actualSurvey .repSelfIdentifyForm .form-group {
	width: 90%;
}

.repSelfIdentifyForm {
	width: 100%;
	margin: 25px 0 0 15px;
}

.repSelfIdentifyForm input {
	width: 100%;
}

.repSelfIdentifyForm label {
	font-size: 12px !important;
}

.actualSurvey .row.cardInstructions{
	margin-top: 25px;
	padding-bottom: 50px;
}

.completedOptionPage {
	font-size: 14px;
}

.completedOptions li {
	padding-bottom: 8px;
}

span.headerDeptName {
	font-size: 16px;
	font-style: italic;
	display: block;
}

textarea, textarea:focus {
	font-size: 16px;
}

.inSurveyLogo {
	margin: 0 auto;
	max-width: 290px;
}

.footerLogo{
	width: 120px !important;
	max-width: 120px !important;
	border: none !important;
	padding-bottom: 0px;
	margin: 0 !important;
	border-radius: 0 !important;
}

.fixed-bottom p.footerLogoText{
	font-size: 10px;
	text-transform: uppercase;
	color: #444;
	font-weight: bold;
}

.firstPage{
	background: transparent !important;
}

.buttonContainer{
	width: 80%;
	margin: 0 auto;
	padding-top: 20px;
	button.landingPageOption{
		display: block;
		border-radius: 0;
		background-color: #0073cb;
		color: #fff;
		width: 100%;
		font-size: 20px;
		margin: 15px 0 20px 0;
		min-height: 30px;
		padding: 10px 0 10px 0;
	}	
	p {
		text-align: center;
		font-size: 18px !important;
	}
}


.searchContainer{
	height: 100%;
	min-height: 75px;
	background-color: #fff;	
	width: 100%;

	div.searchContainerSub {
		div.rbt {
			display: inline-block;
			width: 75%;
		}
		padding: 15px 0px 17px 35px;
		label {
			display: block;
			font-size: 16px !important;
		}
		input{
			width: 100%;
			max-width: 400px;
			height: 35px;
			border: 1px solid #0073cb;
			padding-left: 15px;
			font-size: 16px;
			border-radius: 0;
		}
		button {
			width: 40px;
			height: 35px;
			padding: 0;
			margin: -6px 0 0 -3px;
			border-radius: 0;
			background: #0073cb url("/searchicon.jpg") 0 0 no-repeat;
			background-position: center;
		}
		ul.dropdown-menu {
			border-radius: 0;
		}
	}
}

label.anonymousContainer{
	text-align: center;
	width: 100%;
	font-size: 16px !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.referFriend{
		background-image: url(/Well_IQ_Logomark_White.png);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: 7px;
    font-size: 18px;
    padding: 10px 15px 10px 55px;
}

.referralTitle{
	font-size: 16px !important;
}

.referralText {
	font-weight: bold;
}

.departmentList {
	width: 100%;
	.departmentListHeader {
		background-color: #0073cb;
		padding: 15px;
		color: #fff !important;
		font-size: 16px;
		margin-bottom: 0;
	}	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			width: 100%;
			border-bottom: 1px solid #0073cb;
			p{
				display: inline-block;
				margin-bottom: 0;
			}
			p.departmentImage{
				width: 28%;
				min-width: 100px;
				max-width: 300px;
				border-right: 1px solid #0073cb;
				height:105px;
				min-height: 104px;
				display: table-cell;
  				vertical-align: middle;
			}
			p.departmentName{
				padding: 0px 0px 0px 15px;
				font-size: 20px;
				width: 64%;
				min-width: 200px;
				max-width: 215px;
				min-height: 102px;
				display: table-cell;
  				vertical-align: middle;
				height:105px;
			}
		}
	}
}

.mobileLinks{
	display: none;
}


div.makeMiddle{
	text-align: center;
	margin: 0 auto;
	padding: 0 15px 0 15px;
}

.noHeight{
	max-height: none;
}

.companyHero{
	background: url("/company-hero-768.jpg") 0px 53px no-repeat;
	height: 269px;
	width: 100%;
	background-size: cover;
	background-position-x: 50%;
	position: relative;

	div{
		background: #fff;
		opacity: 0.8;
		max-width: 81%;
		position: absolute;
		bottom: 15px;
		right: 0;
		padding: 15px 10px 10px 15px;
		p {
			font-size: 24px;
			color: #000;
			font-weight: bold;
			margin-bottom: 5px;
		}	
	}

}

.hupcWarning{
	position: absolute;
	top: 675px;
	font-size: 15px; 
	left: 0;
	padding: 0 10px;
}

.peerToPeerSearchLine{
	font-size: 14px !important;
	padding-top: 6px;
}

.companyHero.HRDemo {
	background: url("/company-hero-768-aya.jpg") 0px 30px no-repeat;
	height: 269px;
	width: 100%;
	background-size: cover;
	background-position-x: 50%;
	position: relative;
}

.companyHero.Heidehof {
	background: url("/company-hero-768-heidehof.jpg") 0px 30px no-repeat;
	height: 269px;
	width: 100%;
	background-size: cover;
	background-position-x: 50%;
	position: relative;
}

.companyHero.AristaCare {
	background: url("/company-hero-768-aristacare.jpg") 0px 30px no-repeat;
	height: 269px;
	width: 100%;
	background-size: cover;
	background-position-x: 50%;
	position: relative;
}

.companyHero.IoH {
	background: url("/company-hero-768-ioh.jpg") 0px 30px no-repeat;
	height: 269px;
	width: 100%;
	background-size: cover;
	background-position-x: 50%;
	position: relative;
}

.departmentList.staffDeptHeader{
	padding-top: 50px;
	.departmentName {
		color: #000;
	}
}

.peertopeer.departmentList.staffDeptHeader {
	padding-top: 35px !important;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
	}
}

.surveyPadTop {
	margin-top: 25px;
}

.surveyPageInstruction {
	font-weight: bold !important;
	padding-top:10px;
}




	.employerBtn, .employerBtn:hover, .employerBtn:active, .employerBtn:focus{
		position: absolute;
		border-radius: 15px;
	    font-size: 16px;
	    font-weight: bold;
	    padding: 12px 18px;
	    text-transform: uppercase;
	    right: 20px;
	}

	.employerBtn.final {
		right: 0;
		border-radius: 15px 0 0 15px;
		background-color: #145a8c !important;
		z-index: 1000;
		cursor: pointer;
	}

	.employerBtnFeedback{
		right: 0 !important;
		bottom: 10px;
		position: fixed !important;
		border-radius: 15px 0 0 15px !important;
	}

@media only screen and (min-width: 767px){
	.actualSurvey .staffImage img {
		max-width: 150px;
	}
	.row.cardholder {
		max-width: 1000px;
		margin: 0 auto;
	}
	p.poorReviewText{
		max-width: 700px;
		margin: 0 auto !important;
	}

	.inlineNextButton{
		position:relative;
	}

	.badgeSelector.intakeForm {
		min-height: 330px !important;
	}

	.nextBtn, .nextBtn.employerBtn, .nextBtn.employerBtnFeedback{
		font-size: 18px !important;
		padding: 14px 18px !important;
	}

	.companyHero{
		height: 375px;
	}

	.companyHero.AristaCare {
		background: url("/company-hero-large-aristacare.jpg") 0px 35px no-repeat;
		height: 350px;
		width: 100%;
		background-size: cover;
		background-position-x: 50%;
		position: relative;
	}

	.companyHero.Heidehof {
		background: url("/company-hero-large-heidehof.jpg") 0px 35px no-repeat;
		height: 350px;
		width: 100%;
		background-size: cover;
		background-position-x: 50%;
		position: relative;
	}

}


@media only screen and (max-width: 374px) {
  .badgeSelector {
  	padding-left: 1px;
  }
  .actualSurvey .nopad{
  	min-width: 105px;
  	max-width: 105px;
  }
  .row.cardholder.staffList {
  	padding-left: 10px;
  	padding-right: 10px;

  }
    .row.cardholder.staffList .nopad {
  		min-width: 100px;
  		max-width: 100px;
  	}
}

@media only screen and (max-width: 480px) {
	.employerReviewSection {
		width: 100% !important;
		text-align: center;
	}
}

@media only screen and (max-width: 767px){
	.hupcWarning{
		top: 550px;
	}

	.mobileLinks{
		display: block;
		text-align: center;
	}

}

.exceptionSubHead {
	margin-top: 0 !important;
}

@keyframes shadow-pulse
{
     0% {
          box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
     }
     100% {
          box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
     }
}

.react-turntable-section-btn {
	animation: shadow-pulse 1s 4;
}

@media only screen and (min-width: 1300px) {
	.companyHero{
		background-position-y: -77px;
		height: 375px;
	}
	.companyHero.HRDemo, .companyHero.IoH, .companyHero.AristaCare {
		background-position-y: 0px;
		height: 500px;
	}
}