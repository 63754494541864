@import "../../style-vars.scss";

.case-list-container {

    padding-left: $page-margin-size;

    table.case-list {

        tbody {
            tr {
                height: 65px;
                td {
                    vertical-align: middle;
                    padding: 15px 8px 15px 8px;
                }
                td.staffImage img { max-height: 75px;}
            }
        }

        th.sortable:hover {
            cursor: pointer;
        }
    }

    .case-list-summary {
        font-size: 13px;
        font-weight: 500;
        color: $deemphasized-text-color;
        padding: 0 0 8px 8px;
    }

    .elementSpacer {
        margin-bottom: 10px;
    }

    .filterByDepartmentGroup {
        width: 30%;

        select {
            width: 55% !important;
            display: inline-block;
            margin-right: 15px;
        }

        button {
            margin-top: -5px;
        }
    }
}

#root {
    form.case-list-filters {
        label.control-label {
            text-transform: none;
            font-size: 15px;
            font-weight: normal;
            color: $deemphasized-text-color;
        }

        input {
            background-color: #f9fafc;
            border-radius: 100px;
            border: none;
            padding-left: 15px;
        }

        input:focus {
            border: none;
        }

        input:active {
            border: none;
        }
    }
}

.altRowColor {
    background-color: #fafafa;
}

.case-list-filters input {
    margin-left: 10px;
}
