@import "../../style-vars.scss";

$navbar-active-indicator-height: 4px;
$navbar-active-indicator-color: #4ca1ff;

nav.navbar {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-image: linear-gradient(102deg, #374c89, #3c4c89 44%, #713f7f);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  border-bottom: none;
  margin-bottom: 0;

  .navbar-text {
    line-height: 20px;
    color: $deemphasized-text-color;
    font-weight: normal;
    font-size: 12px;
  }

  .navbar-nav {
      a {
        background: none;
        color: white;
        display: inline-block;
      }

      a:hover {
        border-bottom: $navbar-active-indicator-height solid lighten($navbar-active-indicator-color, 10);
      }

      a:focus {
        background: none;
    }

    
      a.active {
        background: none;
        border-bottom: $navbar-active-indicator-height solid $navbar-active-indicator-color;
      }

      a.active:hover {
        background: none;
      }

      a.active:focus {
        background: none;
      }
    
  }
}

.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

@media only screen and (min-width: 768px) {
  nav.navbar {
    padding: 0 20px 0 !important;

    a {
      padding: 15px 0;
    }
  }
}

@media only screen and (min-width: 1200px) {
  nav.navbar {
    padding: 0 108px 0 !important;
  }
  nav.submenuNav {
    padding-left: 0px !important;
  }
}