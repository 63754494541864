@import "../../style-vars.scss";

.surveyReportView {
  max-width: 1200px;
  outline: 1px solid #ccc;
}

.reportCell{
  background-color: #efefef;
    font-size: 18px;
    width: 25%;
    text-align: right;
    padding-right: 25px !important;
    border-right: 1px solid #ccc;
    border-color: #ccc !important;
    border-top: 0 !important;
}

.staffImageCell{
  background-color: #fff;
  text-align: center;

  img {
    height: 145px;
  }
}

.reportValue {
  font-size: 17px;
  width: 75%;
  padding-left: 25px !important;
  border-color: #eeeeee !important;
}

.reportStaffTitle{
  font-size: 14px;
  font-style: italic;
}

.rowEmphasis{
  border-top: 2px solid #ccc;
}

.spanningReportCell {
  background-color: #fefefe;
  height: 25px !important;
  font-size: 16px;

  label p {
    font-size: 14px;
    padding-top: 15px;
  }
}

.surveyReportView.eventData{
  outline: 1px solid #fb4040;
  tr { height: 50px !important; }
  .reportCell{
    background-color: #fb4040;
    color: #ffffff;
  }
  .reportValue{
    color: #000 !important;
  }
  .reportValue.buttonAction{
    text-align: center;
  }
  .reportValue.textEntryArea{
    padding: 0 !important;
    margin: 0 !important;
    height: 75px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 15px 0 0 25px;
      margin: 0;
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}

@media print {
  h1.page-header{
    padding: 10px;
    margin-left: 50px;
  }
  small.backLink, button.btn {
    display: none;
  }
  p.intakeBadgeName{
    font-size: 14px !important;
  }
}