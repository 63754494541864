@import "../../style-vars.scss";

.case-list-container {

    padding-left: $page-margin-size;

    table.case-list {

        tbody {
            tr {
                height: 65px;
                td {
                    vertical-align: middle;
                    padding: 15px 8px 15px 8px;
                }
                td.staffImage img { max-height: 75px;}
            }
        }

        th.sortable:hover {
            cursor: pointer;
        }
    }

    .case-list-summary {
        font-size: 13px;
        font-weight: 500;
        color: $deemphasized-text-color;
        padding: 0 0 8px 8px;
    }

    .elementSpacer {
        margin-bottom: 10px;
    }

    .filterByDepartmentGroup {
        width: 30%;

        select {
            width: 75%;
            display: inline-block;
            margin-right: 15px;
        }

        button {
            width: 15%;
            margin-top: -5px;
        }
    }
}

#root {
    form.case-list-filters {
        label.control-label {
            text-transform: none;
            font-size: 15px;
            font-weight: normal;
            color: $deemphasized-text-color;
        }

        input {
            background-color: #f9fafc;
            border-radius: 100px;
            border: none;
            padding-left: 15px;
        }

        input:focus {
            border: none;
        }

        input:active {
            border: none;
        }
    }
}

.case-list-filters input {
    margin-left: 10px;
}

nav.submenuNav {
    background-image: none;
    box-shadow: none;
    border: 0;
    color: #414142;
    background-color: #fff;
    padding-left: 0;

    .navbar-nav li a {
        color: #4c6072;
        font-size: 13px;
        border-bottom: 4px solid #FFF;
    }

    .navbar-nav li a:hover, .navbar-nav .active a { 
        border-bottom: 4px solid #374c89;
    }
}

.adminBadgeView {
    padding-right: 25px;
}

table.settingsHeader{
    width: 90%;
    th, td { width: 33%; }
    thead tr { background-color: #374c89; }
    thead tr th {  color: #fff;}
}